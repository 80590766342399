.MuiAppBar-colorPrimary {
    background-color: #ffffff !important;
    color: #000000 !important;
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
    border-bottom: 2px solid #000000;
}

.mainText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8rem !important;
}
