@import url(http://fonts.cdnfonts.com/css/sf-pro-display);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

#root {
  background-color: #ffffff;
}
html,
body,
.root {
    /* Global Styling */
    width: 100%;
    height: 100%;
    font-family: 'SF Pro Display', Arial, Helvetica, sans-serif;
}

#root {
    display: flex;
    height: 100%;
    justify-content: center;
    font-family: 'SF Pro Display', Arial, Helvetica, sans-serif;
}

.MuiBox-root .MuiTypography-root,
.MuiBox-root .MuiButton-root span,
.MuiButtonBase-root span,
.MuiGrid-root .MuiButtonBase-root,
.MuiGrid-root .MuiMenuItem-root,
.MuiTypography-root *,
button span:not(span.fas,span.fa) {
    font-family: 'SF Pro Display', Arial, Helvetica, sans-serif;
}

.fa, .far, .fas {
    font-family: "Font Awesome 5 Free", sans-serif !important;
}


.App {
    width: 100%;
    height: 100%;
    overflow-y: hidden !important;
    overflow-x: auto !important;
}

.App-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.round-border1 .MuiOutlinedInput-root {
    border-radius: 4rem !important;
}

.round-border2 .MuiOutlinedInput-root {
    border-radius: 0.5rem !important;
}

.round-border1.MuiButtonBase-root {
    border-radius: 4rem !important;
}

.header-logo-container {
    display: flex;
    align-items: center;
    width: 300px;
    max-height: 64px;
    box-sizing: border-box;
}

header {
    padding: 0 !important;
    margin: 0 !important;
}

/* .header-logo-container img {
    height: auto !important;
    position: static !important;
    width: 100% !important;
    max-width: 12rem;
    padding-left: 3rem;
} */

.header-logo-container img {
    max-height: 48px;
    width: auto;
    padding-left: 32px;
}

.header-right-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 1rem;
    flex: 1 1;
    box-sizing: border-box;
}

.Logo-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
}

.Logo-container div {
    display: flex !important;
    align-items: center !important;
    padding-left: 2rem !important;
    width: 100% !important;
}

.Logo-container img {
    position: static !important;
    height: auto !important;
    max-width: 9rem;
}

.mainText span {
    font-family: Arial, Helvetica, sans-serif;
    font-size: '1.5rem';
}

.subItem span {
    margin-left: '2rem';
}

.subText span {
    opacity: 0.5;
}

.small-text {
    text-transform: none!important;
}

.gmap-label {
    position: relative;
    bottom: 24px
}

.navicon {
    width: 24px;
    height: auto;
    max-height: 24px;
}

.word-break-all {
    word-break: break-all;
}

.MuiAppBar-colorPrimary {
    background-color: #ffffff !important;
    color: #000000 !important;
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
    border-bottom: 2px solid #000000;
}

.mainText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8rem !important;
}


.status-img-size {
    height: 14px;
    width: 14px;
    padding-right: 10px;
}
